import { IPageSeoWrapperApiModel } from '../pages/pages';

export const EXAM_ID = 'exam';

export enum AcademySectionType {
    FAQ = 'faq',
    NEWS = 'news',
}

/**
 * Типы сложностей курса
 */
export enum CoursesComplexityTags {
    Newbie = 'newbie',
    Experienced = 'experienced',
    Expert = 'expert',
}

/**
 * Типы форматов курса
 */
export enum CoursesFormatTags {
    Video = 'video',
    Content = 'content',
}

/**
 * Типы прогресса по курсу
 */
export enum CoursesProgressTags {
    Active = 'active',
    Passed = 'passed',
}

/**
 * Теги фильтрации курсов
 */
export type CoursesTags = CoursesComplexityTags | CoursesFormatTags | CoursesProgressTags;

/**
 * Типы фильтров
 * - по сложности
 * - по формату курса
 * - по прогрессу курса
 */
export enum CoursesTagsType {
    Complexity = 'complexity',
    Format = 'format',
    Progress = 'progress',
}

/**
 * Query параметры курсов
 */
export interface ICoursesApiQuery {
    /**
     * Значение фильтров
     */
    filter?: string;
    /**
     * Значение поиска
     */
    search?: string;
}

export interface ICourseBlockWithTest {
    /**
     * Идентификатор теста
     */
    test_slug?: string;
    /**
     * Количество вопросов в тесте
     */
    test_questions_count?: number;
    /**
     * Максимальная оценка за экзамен
     */
    total_mark?: number;
}

/**
 * Модель блока урока
 */
export interface ILessonBlock extends ICourseBlockWithTest {
    /**
     * Идентификатор блока
     */
    id: string;
    /**
     * Название блока
     */
    title: string;
    /**
     * Ожидаемое время на блок для видео формата (в минутах)
     */
    video_estimated_time: number;
    /**
     * Ссылка на YouTube видео
     */
    video_url?: string;
    /**
     * ID видео, для встраивания на сайт
     */
    video_id?: string;
    /**
     * Превью картинка YouTube видео
     */
    video_preview_url?: string;
    /**
     * Текстовое представление блока(HTML)
     */
    content?: string;
    /**
     *  Ожидаемое время на блок для текстового формата (в минутах)
     */
    content_estimated_time: number;
}

/**
 * Типы форматов, с помощью которых можно пройти тест
 */
export type CourseFormat = 'video' | 'content'

export interface ICourseStore {
    /**
     * Название курса в ссылке
     */
    course_slug: string;
    /**
     * Идентификатор текущего блока курса.
     * Тут может быть как обычный блок, так и экзамен.
     * Если значение здесь равно @see EXAM_ID это означает, что сейчас открыт экзамен.
     */
    current_block_id: string;
    /**
     * Текущий выбранный формат теста
     */
    format: CourseFormat;
    /**
     * Уникальный идентификатор курса
     */
    course_id: number;
}

export type IExamInfo = ICourseBlockWithTest

/**
 * Типы сложностей курса
 */
export enum CourseComplexity {
    Newbie = 'newbie',
    Experienced = 'experienced',
    Expert = 'expert',
}

/**
 * Перевод в читабельный текст сложностей курса
 */
export const CourseComplexityDictionary = {
    [CourseComplexity.Newbie]: 'Новичкам',
    [CourseComplexity.Experienced]: 'Опытным',
    [CourseComplexity.Expert]: 'Экспертам',
};

export interface ICourseQueryOptions {
    /**
     * Секретный ключ для доступа к курсу.
     */
    secret_key?: string;
}

/**
 * Модель с информацией о курсе
 */
export interface ICourseInformationApiModel {
    /**
     * Имя курса в ссылке
     */
    slug: string;
    /**
     * Уникальный идентификатор курса
     */
    id: number;
    /**
     * Название курса
     */
    title: string;
    /**
     * Описание курса(HTML)
     */
    description: string;
    /**
     * Сложность курса
     */
    complexity: CourseComplexity;
    /**
     * Ожидаемое время на весь курс для видео формата (в минутах)
     */
    video_estimated_time: number | null;
    /**
     * Ожидаемое время на весь курс при прохождении для текстового формата (в минутах)
     */
    content_estimated_time: number | null;
    /**
     * Есть ли экзамен
     */
    has_exam: boolean;
    /**
     * Информация о финальном тесте
     */
    exam: IExamInfo;
    /**
     * Является ли курс закрытым.
     */
    private: boolean;
    /**
     * Превью курса
     */
    preview_url: string;
    /**
     * Alt тег превью
     */
    preview_alt: string;
    /**
     * id, получаемого навыка
     */
    achievement_id: string;
    /**
     * Количество блоков
     */
    blocks_count: number;
    /**
     * Уроки внутри курса
     */
    blocks: ILessonBlock[];
    /**
     * SEO информация для страницы курса
     */
    seo: IPageSeoWrapperApiModel;
    /**
     * Максимальная оценка за экзамен
     */
    total_mark?: number;
}

export type CourseItemType = Omit<ICourseInformationApiModel, 'blocks' | 'seo'>;

export interface ICourseBlockStateApiModel {
    /**
     * Идентификатор урока
     */
    id: string;
    /**
     * Блок пройден/не пройден
     */
    passed: boolean;
}

export interface ICourseStateApiModel {
    /**
     * Все блоки пройдены, финальный тест в расчет не идет
     */
    completed: boolean;
    /**
     * финальный тест успешно пройден
     */
    passed: boolean;
    /**
     * Пройденные уроки
     */
    blocks: ICourseBlockStateApiModel[];
    /**
     * Оценка за экзамен
     */
    mark?: number;
    /**
     *  UUID для доступа к ответам на тест
     */
    response_uuid?: string;
    /**
     * Комментарий об успешности сдачи (если указан, то выводить вместо better_than_part)
     */
    results_comment?: string;
    /**
     * Процент пользователей из статистики для отображения в блоке "вы ответили лучше, чем ... % пользователей"
     */
    better_than_part?: number;
}

/**
 * Модель ачивки академии
 */
export interface IAchievementItemApiModel {
    /**
     * уникальный идентификатор ачивки
     */
    id: string;
    /**
     * Получил ли текущий инвестор эту ачивку
     */
    achieved: boolean;
    /**
     * Иконка награды
     */
    icon: string;
    /**
     * Название курса
     */
    title: string;
    /**
     * Описание курса (HTML)
     */
    description: string;
    /**
     * Превью описание курса
     */
    preview_description: string;
    /**
     * Количество инвесторов, получивших данную награду
     */
    investors_count: number;
    /**
     * Фон карточки награды
     */
    background_color?: string;
    /**
     * Открывать ли текущему инвестору модальное окно с ачивкой
     */
    show: boolean;
    /**
     * Ссылка на курс
     */
    referral_link?: string;
    /**
     * Текст рассылки
     */
    referral_title?: string;
    /**
     * Ссылка кнопки
     */
    action_link?: string;
    /**
     * Текст кнопки
     */
    action_title?: string;
    /**
     * Заголовок блока с информацией о том, что юзер получил награду
     */
    achieved_title?: string;
    /**
     * Описание полученной награды
     */
    achieved_description?: string;
}

/**
 * Пользовательская информация о курсе
 */
export interface IUserCoursesItemApiModel {
    /**
     * Уникальный идентификатор курса
     */
    id: number;
    /**
    * Пройден ли курс
    */
    passed: boolean;
    /**
    * Пройденное количество блоков
    */
    passed_blocks_count: number;
    /**
    * Пройденное время курса (в минутах)
    */
    passed_estimated_time: number;
    /**
    * Полученный бал
    */
    mark: number;
}

/**
 * Информация о рекламном курсе
 */
export interface IPromotionCourseApiModel {
    /**
     * Имя курса в ссылке
     */
    slug: string;
    /**
     * Уникальный идентификатор курса
     */
    id: number;
    /**
     * Рекламный текст
     */
    title: string;
    /**
     * Рекламный баннер
     */
    image_url: string;
    /**
     * Рекламный баннер для мобильной версии
     */
    mobile_image_url: string;
}

/**
 * Модель описывающая спикера курсов в академии.
 */
export interface IAcademySpeakerApiModel {
    /**
     * ФИО
     */
    name: string;
    /**
     * Должность
     */
    position: string;
    /**
     * Ссылка на фотографию
     */
    image_url: string;
    /**
     * Alt тег фотографии
     */
    image_alt: string;
}

/**
 * Интерфейс описывающий ссылку на блок курса.
 */
export interface ICourseBlockApiRef {
    /**
     * Идентификатор курса.
     */
    course_id: number;
    /**
     * Идентификатор блока в курсе.
     */
    block_id: string;
}

/**
 * Модель данных описывающая формат запроса на прохождение блока
 */
export type ICourseBlockPassPayload = ICourseBlockApiRef;

/**
 * Модель данных описывающая формат запроса на просмотр блока
 */
export type ICourseBlockSeenPayload = ICourseBlockApiRef;

/**
 * Модель данных описывающая формат запроса на прохождение блока
 */
export interface IAchievementSeenPayload {
    /**
     * Идентификатор ачивки
     */
    id: string;
}

/**
 * Результат маппинга ачивок по идентификатору
 */
export interface IAchievementsMapResult {
    [key: string]: IAchievementItemApiModel;
}

/**
 * Хранилище страницы курсов
 */
export interface ICoursesState {
    /**
     * Курсы
     */
    courses: ICourseInformationApiModel[];
    /**
     * Отфильтрованные курсы
     */
    filteredCourses: ICourseInformationApiModel[];
    /**
     * Типы фильтра
     */
    filterType: CoursesTagsType;
    /**
     * Выбранный фильтр
     */
    selectedTag: CoursesTags | null;
    /**
     * Текст поисковой строки
     */
    search: string;
}